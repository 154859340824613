import { defineStore } from 'pinia'
import { reactive } from 'vue'
import type { APIResponse } from 'playwright-core'
import userApi from '#/api/user'
import type { ReqPayloadLogin, ReqSignupPayload } from '#/types/api/user'
import { useAppStore } from '#/stores/appStore'

interface State {
  activeUser: null | string
}

const useUserStore = defineStore(
  'user',
  () => {
    const state = reactive<State>({
      activeUser: null,
    })

    const loading = reactive({
      activeUser: false,
    })

    const { setAuth } = useAppStore()
    const router = useRouter()

    const setCurrentUser = ({
      user,
      token,
      expiresIn,
    }: {
      user: string
      token: string
      expiresIn: string
    }) => {
      state.activeUser = user
      setAuth(token, expiresIn)
    }
    const login = async (payload: ReqPayloadLogin): Promise<APIResponse> => {
      const res = await userApi.login(payload)
      const resData = await res.json()
      if (resData.success) {
        setCurrentUser(resData.data)
        await router.push({ name: 'app-index-dashboard' })
      }

      return resData
    }

    const signUp = async (payload: ReqSignupPayload): Promise<APIResponse> => {
      try {
        const res = await userApi.signup(payload)
        return await res.json()
      }
      catch (e) {
        console.error(e)
        throw e
      }
    }

    const resetPassword = async (
      payload: Pick<ReqSignupPayload, 'email'>,
    ): Promise<APIResponse> => {
      const res = await userApi.resetPassword(payload)
      return await res.json()
    }

    const logout = async () => {
      setAuth('', '')
      state.activeUser = null
      await router.push({ name: 'auth-index-login', hash: 'logoutSuccess' })
    }

    const updatePassword = async (
      payload: Record<string, unknown>,
    ): Promise<APIResponse> => {
      const res = await userApi.updatePassword(payload)
      return await res.json()
    }

    const resendConfirmationEmail = async (payload: {
      email: string
    }): Promise<APIResponse> => {
      const res = await userApi.resendConfirmationEmail(payload)
      return await res.json()
    }

    return {
      state,
      loading,
      login,
      signUp,
      resetPassword,
      updatePassword,
      resendConfirmationEmail,
      logout,
    }
  },
  {
    persist: true,
  },
)

export default useUserStore
