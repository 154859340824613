import endpoints from '#/endpoints/user'
import type { ReqPayloadLogin, ReqSignupPayload } from '#/types/api/user'
// const config = useRuntimeConfig();
const {
  newPasswordUrl,
  resetPasswordUrl,
  loginUrl,
  updateUrl,
  logoutUrl,
  signupUrl,
  resendConfirmEmailUrl,
} = endpoints

const baseHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': 'true',
}

export default {
  async login(payload: ReqPayloadLogin) {
    return await fetch(loginUrl, {
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  async update(payload: string, options: Record<string, unknown>) {
    return await fetch(updateUrl(payload), {
      headers: baseHeaders,
      ...options,
      body: JSON.stringify(payload),
    })
  },
  async logout() {
    return await fetch(logoutUrl, {})
  },
  async signup(payload: ReqSignupPayload, options?: Record<string, unknown>) {
    return await fetch(signupUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  async resetPassword(
    payload: Pick<ReqSignupPayload, 'email'>,
    options?: Record<string, unknown>,
  ) {
    return await fetch(resetPasswordUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  async updatePassword(
    payload: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) {
    return await fetch(newPasswordUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  async resendConfirmationEmail(
    payload: { email: string },
    options?: Record<string, unknown>,
  ) {
    return await fetch(resendConfirmEmailUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
}
